@import "de-govuk";

$jt-border-radius: 5px !default;

@import "accessible-autocomplete";

input[type=text]:read-only {
  border: 0;
  background-color: #f3f2f1;
}

// Honeypot
.govuk-form-group._seor23r {
  line-height: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  border: none;
  opacity: 0;
  position: absolute;
}

.govuk-input, .govuk-textarea {
  @include de-govuk-inputs;
}

.govuk-input__wrapper.jt-currency {
  .govuk-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .govuk-input__prefix {
    @include de-govuk-inputs;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.govuk-button {
  background-color: $jt-primary;
  border-radius: $jt-border-radius;
  box-shadow: none;

  &:hover {
    background-color: darken($jt-primary, 15%);
  }

  &--secondary,
  &--secondary:link,
  &--secondary:visited,
  &--secondary:active,
  &--secondary:hover {
    background-color: $jt-secondary;
    color: white;
  }
}

// Not a GOV.UK class as such; helpful when we do things like present a choice
// between a primary button and a link that follows it. See in_progress.html.erb for an example
.govuk-button--height {
  line-height: 1.1875;
  display: inline-block;
  margin-bottom: 22px;
  padding: 8px 10px 7px;
  vertical-align: top;
}

.govuk-panel.referral-panel {
  text-align: left;
  background-color: darken($jt-primary, 25%);

  .govuk-panel__body {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;

    .referral-link-container {
      border: 1px solid white;
      width: 100%;
      border-radius: $jt-border-radius;
      overflow: hidden;
      padding: 0.4em;
      white-space: nowrap;

      a {
        display: inherit;
        $fade-long-url: linear-gradient(to right, white 80%, transparent);
        font-size: 50%;
        color: white;
        mask-image: $fade-long-url;
        -webkit-mask-image: $fade-long-url;
      }
    }

    .button-sharing {
      display: none;
      min-width: 8.5em;
      margin: 0.5em 0 0 5px;
      padding: 11px 5px 7px 8px;

      .js-enabled & {
        display: block;
      }

      &::before {
        background-color: white;
        height: 20px;
        width: 20px;
        position: relative;
        display: inline-block;
        margin-left: -18px;
        vertical-align: -33.3333%;
      }

      &.button-sharing__copy {
        &::before {
          mask-image: url('icons/copy.svg');
          -webkit-mask-image: url('icons/copy.svg');
        }
      }
      &.button-sharing__share {
        &::before {
          mask-image: url('icons/share.svg');
          -webkit-mask-image: url('icons/share.svg');
        }
      }
    }
  }
}
