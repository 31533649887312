@mixin de-govuk-box-border {
  box-shadow: 0 0 3pt 2pt #999;
  outline: 0;
}

@mixin de-govuk-inputs {
  border-radius: $jt-border-radius;
  border-width: 1px;
  &:focus {
    @include de-govuk-box-border;
  }
}

.govuk-tag {
  background-color: $jt-secondary;
  color: #ffffff;
}
