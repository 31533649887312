$landing-border-radius: 11px;

#landing {
  @include govuk-media-query($from: tablet) {
    &.govuk-main-wrapper {
      padding-top: 1em; // when no logo on tablet and up, remove the whitespace at the top
    }
  }

  .cta-reverse {
    background-color: white;
    color: $jt-primary;
    margin: 0;
    padding: 0.5em;
    font-weight: bold;
    width:100%;
  }

  .pre-existing-problems {
    font-size: smaller;
    color: white;

    a {
      color: white;
    }
  }

  .landing-copy {
    z-index: 0;
    position: relative;

    .logo { // top logo for small screens
      display: none;
      position: absolute;

      @include govuk-media-query($until: tablet) {
        display: block;
        z-index: 1;
        top: 0;
        right: 0;
        padding: 2.5%;
        background-color: white;
        width: 150px;
        img {
          width: 150px;
        }
      }
    }

    .hero-heading {
      position: relative;
      margin-bottom: 0;

      img.hero {
        display: block;
        box-sizing: border-box;
        border: 0;
        width: 100%;
        height: auto;

        @include govuk-media-query($from: tablet) {
          width: 150%;
          border: $landing-border-radius solid $jt-primary;
          border-radius: $landing-border-radius;
        }
      }

      .overlay-text {
        position: absolute;
        bottom: $landing-border-radius;
        left: $landing-border-radius;
        width: 105%;
        min-height: 4em;
        box-sizing: border-box;
        margin: 0;
        padding: 0.85em 1em 0.75em 1em;
        color: white;
        background: rgba(25, 25, 25, 0.8);

        @include govuk-media-query($from: tablet) {
          border-bottom-left-radius: $landing-border-radius / 2;
        }
        @include govuk-media-query($until: tablet) {
          position: static;
          width: 100%;
          text-align: center;
          background: $jt-primary;
          color: white;
          margin-top: -10px;
        }
      }
    }

    .mobile-cta {
      @include govuk-media-query($from: tablet) { display: none; }
      padding: 0 1em 1em;
      background-color: $jt-primary;
    }

    .member-benefits {
      padding: 1em;
      margin-top: 0;

      h2 {
        color: $jt-primary;
        margin: 0.2em 0;
      }

      li {
        h3 {
          font-size: 1.2em;
          @include govuk-media-query($from: tablet) { font-size: 1.7rem }
          margin: 0.5em 0;
        }
        p {
          margin-top: 0.5em;
        }
      }
    }

    .cta-block {
      margin: 0 1em 2em 1em;
      padding: 1.5em;
      border-radius: 2%;
      background-color: #ececec;

      h2 {
        font-size: 1em;
        @include govuk-media-query($from: tablet) { font-size: 1.7rem }
      }

      .govuk-button {
        margin-bottom: 0;
        font-weight: bold;
      }
    }
    .member-experience {
      margin: 0 0 2em 1em;
      img {
        border-radius: 50%;
        width: 90px;
        float: left;
        margin: 0 1em 1em 0;
      }
      figcaption {
        font-style: italic;
      }
    }
  }

  .landing-form {
    position: relative;
    padding: 1.5em 2.5em;
    background-color: $jt-primary;
    color: white;
    border-radius: 0;
    z-index: 1;

    .logo img {
      display: none;
    }

    .govuk-heading-m, .govuk-heading-l  {
      color: white;
    }

    @include govuk-media-query($from: tablet) {
      border-radius: $landing-border-radius;

      margin-top: 20%;
      padding: 1em;
      .logo img {
        display: block;
        width: 100%;
        background-color: white;
        padding: 15px;
        box-sizing: border-box;
        margin-bottom: 1em;
      }
    }

    legend {
      h3 {
        font-size: 58%;
        color: white
      }
    }

    fieldset {
      p {
        margin-top: 0;
      }
    }
  }

  .social-proof {
    h2 {
      color: $jt-primary;
    }

    li {
      margin-bottom: 5px;
    }

    padding: 1.5em 2.5em 0;
    background-color: white;

    @include govuk-media-query($from: tablet) {
      margin-top: -1em;
      padding: 2em 1em 1em 1em;
      background-color: #ececec;
    }
  }
}
