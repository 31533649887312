$jt-progress-background: #f3f2f1 !default;
$jt-progress-height: 10px !default;
$jt-progress-width: 100% !default;
$jt-progress-radius: $jt-border-radius !default;
$jt-progress-color: #{scale-color($jt-primary, $lightness: 35%)} !default;

body {
  margin: 0;
}

header.govuk-header.jt-public {
  padding-bottom: 5px;
  border-bottom: none;
  background-color: white;

  .govuk-header__container {
    border-bottom: none;

    a.govuk-header__link, a.govuk-header__link:hover {
      margin-bottom: 0;
      border-bottom: none;
    }
  }
}

%govuk-flash {
  padding: $govuk-gutter-half;
  margin-bottom: $govuk-gutter;
  border: 5px solid;
  font-weight: 700;
}

.govuk-flash {
  &__notice {
    @extend %govuk-flash;
    color: govuk-colour("black");
    border-color: $jt-primary;
  }
  &__alert {
    @extend %govuk-flash;
    color: govuk-colour("black");
    border-color: govuk-colour("red");
  }
}

.govuk-panel--confirmation {
  color: govuk-colour("white");
  background: govuk-colour("dark-grey");;
}

.govuk-back-link {
  margin: 4px 0;
}

progress {
  // For full weirdness explained, see https://www.hongkiat.com/blog/html5-progress-bar/
  border-radius: $jt-progress-radius;
  width: $jt-progress-width;
  height: $jt-progress-height;
  margin: 10px 0;
  // Only affects Firefox!
  color: $jt-progress-color;

  &::-webkit-progress-bar {
    background-color: $jt-progress-background;
    border-radius: $jt-progress-radius;
  }
  &::-webkit-progress-value {
    background-color: $jt-primary;
    background-color: $jt-progress-color;
    border-radius: $jt-progress-radius;
  }
  &::-moz-progress-bar {
    background-color: $jt-primary;
    background-color: $jt-progress-color;
    border-radius: $jt-progress-radius;
    color: $jt-progress-background;
  }
}

.service-user-number {
  float: right;
  .logo {
    @include govuk-media-query($from: mobile)  { width: 100px; }
    @include govuk-media-query($from: tablet)  { width: 100%;}
  }

  @include govuk-media-query($from: mobile) { text-align: inherit }
  @include govuk-media-query($from: tablet) { text-align: center; }
}

nav.pagy {
  font-family: $govuk-font-family;
  font-size: 1.2em;
  a {
    display: inline-block;
    padding: 5px;
    color: $govuk-link-colour;
    text-align:justify;
    font-weight: bold;
  }
  a:hover {
    color: $govuk-link-hover-colour;
  }
}
