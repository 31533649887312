.jt-admin .govuk-header__container {
  margin-bottom: -5px;
  border-bottom: 5px solid $jt-primary;
}

.govuk-header__navigation-item--active a:link,
.govuk-header__navigation-item--active a:hover,
.govuk-header__navigation-item--active a:visited {
  color: lighten($jt-primary, 30%) ;
}

.mfa_qr_code {
  svg {
    display: block;
    max-width: 200px;
    margin: 20px auto;
  }
}
