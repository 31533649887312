@import "de-govuk";

.govuk-link.govuk-link--disabled {
  color: #ababab;
  pointer-events: none;
  @extend .govuk-link--no-underline;
}

.toggle-link-sentence {
  margin-top: 0.5em;
}

.autocomplete-root {
  textarea {
    margin-bottom: 0;
  }
}

/* Copied from accessible-autocomplete/dist */
.autocomplete__wrapper {
  position: relative;

  * {
    @include govuk-typography-common();
  }
}

.autocomplete__hint, .autocomplete__input {
  -webkit-appearance: none;
  border: 2px solid #0b0c0c;
  border-radius: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin-bottom: 0;
  width: 100%
}

.autocomplete__input {
  background-color: transparent;
  position: relative;
  @include de-govuk-inputs;
}

.autocomplete__hint {
  color: #b1b4b6;
  position: absolute
}

.autocomplete__input--default {
  padding: 7px 2px 7px 38px;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
}

.autocomplete__input--focused {
  @include de-govuk-box-border
}

.autocomplete__input--show-all-values {
  padding: 5px 34px 5px 5px;
  cursor: pointer
}

.autocomplete__dropdown-arrow-down {
  z-index: -1;
  display: inline-block;
  position: absolute;
  right: 8px;
  width: 24px;
  height: 24px;
  top: 10px
}

.autocomplete__menu {
  background-color: #fff;
  border: 2px solid #0b0c0c;
  border-top: 0;
  color: #0b0c0c;
  margin: 0;
  max-height: 342px;
  overflow-x: hidden;
  padding: 0;
  width: 100%;
  width: calc(100% - 4px)
}

.autocomplete__menu--visible {
  display: block
}

.autocomplete__menu--hidden {
  display: none
}

.autocomplete__menu--overlay {
  box-shadow: rgba(0, 0, 0, .256863) 0 2px 6px;
  left: 0;
  position: absolute;
  top: 100%;
  z-index: 100
}

.autocomplete__menu--inline {
  position: relative
}

.autocomplete__option {
  border-bottom: solid #b1b4b6;
  border-width: 1px 0;
  cursor: pointer;
  display: block;
  position: relative
}

.autocomplete__option > * {
  pointer-events: none
}

.autocomplete__option:first-of-type {
  border-top-width: 0
}

.autocomplete__option:last-of-type {
  border-bottom-width: 0
}

.autocomplete__option--odd {
  background-color: #fafafa
}

.autocomplete__option--focused, .autocomplete__option:hover {
  background-color: #1d70b8;
  border-color: #1d70b8;
  color: #fff;
  outline: 0
}

@media (-ms-high-contrast: active),(forced-colors: active) {
  .autocomplete__menu {
    border-color: FieldText
  }

  .autocomplete__option {
    background-color: Field;
    color: FieldText
  }

  .autocomplete__option--focused, .autocomplete__option:hover {
    forced-color-adjust: none;
    background-color: SelectedItem;
    border-color: SelectedItem;
    color: SelectedItemText;
    outline-color: SelectedItemText
  }
}

.autocomplete__option--no-results {
  background-color: #fafafa;
  color: #646b6f;
  cursor: not-allowed
}

.autocomplete__hint, .autocomplete__input, .autocomplete__option {
  font-size: 16px;
  line-height: 1.25
}

.autocomplete__hint, .autocomplete__option {
  padding: 5px
}

@media (min-width: 641px) {
  .autocomplete__hint, .autocomplete__input, .autocomplete__option {
    font-size: 19px;
    line-height: 1.31579
  }
}
