$jt-primary: #ff3b30;
$jt-secondary: #555555;
$logo-width: 310px;
$mobile-logo-width: 210px;
$footer-logo-width: 120px;

@import 'govuk_frontend';
@import '_shared/forms';
@import '_shared/layout';
@import '_shared/admin';
@import '_shared/landing';

.govuk-flash__notice {
  border-color: $jt-progress-color;
}
/* Tenant-specific overrides go here */
header.govuk-header.jt-public {
  border-bottom: 1px solid #b1b4b6;

  .govuk-header__container {
    @include govuk-media-query($until: desktop) {
      .govuk-header__logo {
        width: $mobile-logo-width;
        float: none;
        margin-left: auto;
        margin-right: auto;

        .logo img {
          width: $mobile-logo-width;
        }
      }
    }

    @include govuk-media-query($from: desktop) {
      .logo img {
        width: $logo-width;
        margin-left: calc(0px - $logo-width / 7);
      }
    }
  }
}

footer.govuk-footer {
  img.logo {
    width: 150px;
    margin-bottom: 1em;
  }
}
